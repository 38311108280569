import { CommonModule, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import localeAr from '@angular/common/locales/ar'
import localeEn from '@angular/common/locales/en'
import localeEs from '@angular/common/locales/es'
import localePl from '@angular/common/locales/pl'
import localeRu from '@angular/common/locales/ru'
import localeZhHK from '@angular/common/locales/zh-Hant-HK'
import { ErrorHandler, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { RestService, sentryErrorExtractor } from '@mmx/shared'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import * as Sentry from '@sentry/angular'
import { buildenv, environment } from 'environments'

import { MaterialModule } from '../shared/modules/material.module'
import { ClinicExistsGuard } from './guards/clinic.guard'
import { TabletGuard } from './guards/tablet.guard'
import { AppLoaderModule } from './modules/app-loader/app-loader.module'
import { MMXIconModule } from './modules/mmx-icon/mmx-icon.module'
import { AuthInterceptor } from './services/auth-interceptor.service'
import { AuthenticatedRestService } from './services/auth-rest.service'
import { LoadingInterceptor } from './services/loading.interceptor'

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.env,
    release: buildenv.release,
    ignoreErrors: [
      '401 Unauthorized',
      'Non-Error exception captured',
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

registerLocaleData(localeAr, 'ar')
registerLocaleData(localeEn, 'en')
registerLocaleData(localeEs, 'es')
registerLocaleData(localePl, 'pl')
registerLocaleData(localeRu, 'ru')
registerLocaleData(localeZhHK, 'zh-hk')

const thirdPartyModules = [
  RouterModule,
  CommonModule, // provides basic angular template functionality
]

const customModules = [
  AppLoaderModule,
  MMXIconModule,
]

const providers = [
  AuthenticatedRestService,
  ClinicExistsGuard,
  RestService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
      extractor: sentryErrorExtractor,
    }),
  },
  TabletGuard,
]

@NgModule({
  imports: [
    ...thirdPartyModules,
    ...customModules,
    MaterialModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json')
        },
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    ...thirdPartyModules,
    ...customModules,
    MaterialModule,
    TranslateModule,
  ],
  providers: [
    ...providers,
  ],
})
export class CoreModule { }
